<template>
    <div>
        <b-card>
            <b-card-text class="text-center">
                <h2>
                    {{ $t('SubscriptionCard.title') }}
                </h2>

                <div class="d-flex justify-content-center">
                    <p class="text-center font-small-3 mb-0" style="width: 33%">
                        {{ $t('SubscriptionCard.subtitle') }}
                    </p>
                </div>
            </b-card-text>
            <b-card-text class="">
                <div class="d-flex justify-content-center align-items-center">
                    <PaymentToggler
                        @monthly="changePaymentPlan($event)"
                        :fontSize="{fontSize: '28px'}"
                    />
                </div>

                <b-row class="mt-3">
                    <Loading v-if="packages.length == 0" />

                    <template v-else>
                        <b-col
                            v-for="(data, index) in packages"
                            :key="index"
                            xl="3"
                            sm="6"
                            class="p-1"
                        >
                            <b-card
                                class="shadow h-100 border border-secondaryColor rounded-lg"
                            >
                                <b-card-text>
                                    <div class="d-flex">
                                        <h2
                                            class="mb-0 text-uppercase font-weight-bolder"
                                            style="letter-spacing: 2px"
                                        >
                                            {{ data.package.package_name }}
                                        </h2>
                                        <img
                                            v-if="data.currentPlan"
                                            class="checkLargeIcon"
                                            src="@/assets/icon/checkIcon.png"
                                        />
                                    </div>

                                    <div
                                        class="d-flex mt-2 flex-column"
                                        style="gap: 0.5rem"
                                    >
                                        <div class="d-flex" style="gap: 0.5rem">
                                            <img
                                                src="@/assets/icon/checkIcon.png"
                                                width="20px"
                                                height="20px"
                                            />

                                            <p class="mb-0 font-weight-bolder">
                                                {{
                                                    $t(
                                                        'SubscriptionCard.invoiceNumber',
                                                        {
                                                            number: data.number_of_invoices_per_month,
                                                        }
                                                    )
                                                }}
                                            </p>
                                        </div>

                                        <div
                                            class="dropdown-divider mt-2"
                                        ></div>

                                        <div
                                            class="my-5 d-flex justify-content-center"
                                        >
                                            <p
                                                class="font-weight-bolder text-center mb-0 price"
                                                style=""
                                            >
                                                {{ getPrice(data) }}
                                                <span
                                                    class="font-weight-light priceDuration"
                                                >
                                                    SEK /
                                                    {{ $t('Message.month') }}
                                                </span>
                                            </p>
                                        </div>

                                        <!-- <b-button
                                            v-if="data.currentPlan"
                                            disabled
                                            v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="light"
                                            class="text-green-darken-1"
                                        >
                                            {{
                                                $t(
                                                    'SubscriptionCard.currentPackage'
                                                )
                                            }}
                                        </b-button> -->

                                        <b-button
                                            @click="upgradePlan(data)"
                                            v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="green-darken-1"
                                            :disabled="data.loadingStatus"
                                        >
                                            <span v-if="data.loadingStatus">
                                                <b-spinner
                                                    small
                                                    type="grow"
                                                ></b-spinner>
                                                {{ $t('Message.loading') }}...
                                            </span>

                                            <span v-else>
                                                {{
                                                    $t(
                                                        'SubscriptionCard.upgradeNow'
                                                    )
                                                }}
                                            </span>
                                        </b-button>
                                    </div>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </template>
                </b-row>

                <b-button
                    v-if="packages.length > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    :disabled="cancelSubscriptionStatus"
                    v-b-modal.modal-primary
                >
                    <span v-if="cancelSubscriptionStatus">
                        <b-spinner small type="grow"></b-spinner>
                        {{ $t('Message.loading') }}...
                    </span>

                    <span v-else>
                        {{ $t('SubscriptionCard.cancel') }}
                    </span>
                </b-button>
            </b-card-text>
        </b-card>

        <b-modal
            id="modal-primary"
            ok-only
            ref="modal"
            ok-variant="primary"
            :ok-title="$t('SubscriptionCard.ModalConfirm')"
            modal-class="modal-primary"
            centered
            @ok="validationForm"
            clickToClose
            :title="$t('SubscriptionCard.cancel')"
        >
            <b-card-text>
                <validation-observer ref="loginValidation">
                    <b-form class="auth-login-form mt-2">
                        <!-- Reason Options -->
                        <b-form-group>
                            <validation-provider
                                #default="{errors}"
                                :name="$t('SubscriptionCard.ReasonOptionLabel')"
                                rules="required"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="{
                                        'is-invalid': errors.length > 0,
                                    }"
                                >
                                    <v-select
                                        :options="reasonOptions"
                                        :clearable="false"
                                        :placeholder="
                                            $t('SubscriptionCard.ReasonOption')
                                        "
                                        class="per-page-selector-send-method d-inline-block"
                                        v-model="
                                            requestCancelSubscription.reasons_for_cancellation
                                        "
                                    >
                                    </v-select>
                                </b-input-group>
                                <small class="text-danger">
                                    {{ errors[0] }}
                                </small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <validation-provider
                                #default="{errors}"
                                :name="$t('SubscriptionCard.ReasonTextLabel')"
                                vid="reasonText"
                                rules="required"
                                v-if="
                                    requestCancelSubscription.reasons_for_cancellation ==
                                    'Other' || requestCancelSubscription.reasons_for_cancellation ==
                                    'Annat'
                                "
                            >
                                <b-form-textarea
                                    id="textarea-default"
                                    v-model="
                                        requestCancelSubscription.cancellation_text
                                    "
                                    :placeholder="
                                        $t('SubscriptionCard.ReasonText')
                                    "
                                    rows="3"
                                />
                                <small class="text-danger">
                                    {{ errors[0] }}
                                </small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import {billingMixins} from '@/mixins/billingMixins';
import useAppConfig from '@core/app-config/useAppConfig';
import PaymentToggler from '@/@core/components/subscription/PaymentToggler.vue';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required} from '@validations';

export default {
    mixins: [getSubscribersMixins, billingMixins],
    directives: {
        Ripple,
    },
    data() {
        return {
            reasonOptions: this.$t('SubscriptionCard.ReasonOptions'),
            packages: [],
            monthly: '',
            tierList: [],
            requestCancelSubscription: {
                cancellation_text: '',
                reasons_for_cancellation: [],
            },
            cancelSubscriptionStatus: false,
            config: useAppConfig(),
            paymentStatusChange: false,
            required,
        };
    },
    components: {
        Switches,
        ToastificationContent,
        PaymentToggler,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },

    computed: {
        tierMessage() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        async getTier() {
            return this.$useJwt.tierList().then(res => res.data.results);
        },
        async getAllPackages() {
            let currentPlanId = await this.getCurrentPlan();
            let tierList = await this.getTier();

            this.packages = [];
            this.$useJwt.packages().then(res => {
                const {results} = res.data;

                results.forEach(element => {
                    if (element.package) {
                        this.packages.push({
                            ...element,
                            loadingStatus: false,
                            tier: tierList.find(
                                tierItem =>
                                    tierItem.tier.level == element.package.level
                            ),
                            currentPlan:
                                element.package.id == currentPlanId[0]
                                    ? true
                                    : false,
                        });
                    }
                });

                this.packages.sort((a, b) => {
                    return a.package.id - b.package.id;
                });
            });
        },

        async cancelSubscription() {
            this.cancelSubscriptionStatus = true;
            const uuid = await this.getUuid();

            // Ensure reasons_for_cancellation is a list before sending the request
            const payload = {
                ...this.requestCancelSubscription,
                reasons_for_cancellation: Array.isArray(this.requestCancelSubscription.reasons_for_cancellation)
                    ? this.requestCancelSubscription.reasons_for_cancellation
                    : [this.requestCancelSubscription.reasons_for_cancellation]
            };

            this.$useJwt
                .cancelSubscription(uuid, payload)
                .then(res => {
                    this.$nextTick(() => {
                        this.$refs['modal'].toggle('#toggle-btn');
                    });

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Message.Success'),
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data[this.message],
                        },
                    });

                    this.$refs.loginValidation.reset();

                    this.requestCancelSubscription = Object.assign({
                        cancellation_text: '',
                        reasons_for_cancellation: [],
                    });
                })
                .finally(() => {
                    this.cancelSubscriptionStatus = false;
                });
        },

        getPrice(price) {
            return this.monthly
                ? price.quarterly_base_price_monthly_average
                : price.yearly_base_price_monthly_average;
        },

        changeTier(value) {
            return new Promise(resolve => {
                this.$useJwt
                    .changeTier({tier: value})
                    .then(res => {
                        this.getAllPackages();
                        this.popup(
                            this.$t('Message.Success'),
                            'CheckIcon',
                            'success',
                            res.data[this.tierMessage]
                        );
                    })
                    .catch(err => {
                        if (err.response) {
                            this.popup(
                                this.$t('Message.Error'),
                                'AlertTriangleIcon',
                                'danger',
                                err.response.data[this.tierMessage]
                            );
                        }
                    })
                    .finally(() => resolve(false));
            });
        },

        validationForm(event) {
            event.preventDefault();

            this.$refs.loginValidation.validate().then(res => {
                if (res) this.cancelSubscription();
            });
        },

        async upgradePlan(value) {
            const {tier_name} = value.tier.tier;
            if (this.paymentStatusChange) {
                value.loadingStatus = true;
                this.updatePaymentPlan(this.monthly, value).then(res => {
                    value.loadingStatus = res;
                });
            }

            if (!value.currentPlan) {
                value.loadingStatus = true;

                this.changeTier(tier_name).then(
                    res => (value.loadingStatus = res)
                );
            }
        },

        changePaymentPlan(value) {
            if (value.hasOwnProperty('paymentStatusChange')) {
                this.monthly = value.monthly;

                this.paymentStatusChange = value.paymentStatusChange;
            } else this.monthly = value.monthly;
        },
    },

    async mounted() {
        this.getAllPackages();
    },
};
</script>

<style scoped lang="scss">
.checkLargeIcon {
    position: absolute;
    top: 2px;
    right: 6px;
    width: 25px;
}

.price {
    font-size: 56px;
    height: 60px;
}

.priceDuration {
    font-size: 20px;
}

.per-page-selector-send-method {
    width: 100%;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>
